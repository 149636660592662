
<template>
 
      <RouterView></RouterView>

</template>
<script>
import { defineComponent } from 'vue'
import { RouterView } from "vue-router";
export default defineComponent({
  name: 'App',

  data() {
    return {
      //
    }
  },
})
</script>

